import React from 'react';
import AffordableIcon from '@material-ui/icons/LocalShipping';
import EasyIcon from '@material-ui/icons/Timelapse';
import TrustedIcon from '@material-ui/icons/Star';
import styles from './FeatureList.styles';
import { css } from 'aphrodite';
import STYLES from '../../../styles/shared';
import COLORS from '../../../styles/colors';
import { isMobile } from 'react-device-detect';

const FeatureList = () => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <div className={css(STYLES.column)} style={{paddingTop: 64, paddingBottom: 64}}>
        <div className={css(styles.mainFeature)}>
          <div className={css(styles.mainFeatureImgContainer)}>
            <img alt={'Courier delivery app'} className={css(styles.mainFeatureImg, STYLES.cardBoxShadow)}
                 src={require('../../../assets/drivers/courier_new.png')}/>
          </div>
          <div className={css(styles.mainFeatureDescContainer)}>
            <h1 style={{color: COLORS.jjdGreyish, fontWeight:600}}>Save up to 50% on {!isMobile && <br/>}your next move</h1>
            <p style={{paddingBottom: 32, color: COLORS.jjdGreyish}}>JJD is much cheaper than traditional removal companies</p>
          </div>
        </div>
      </div>

      <div className={css(styles.moreFeatures)}>
        <div className={css(styles.featureContainer)}>
          <AffordableIcon className={css(styles.icon)}/>
          <h2 className={css(styles.h2)}>Fast service</h2>
          <p className={css(styles.p)}>Choose any arrival date and time - we can even be at your pickup location in an hour</p>
        </div>
        <div className={css(styles.featureContainer)}>
          <EasyIcon className={css(styles.icon)}/>
          <h2 className={css(styles.h2)}>Available 24/7</h2>
          <p className={css(styles.p)}>Prices are about the same as an UberXL for most jobs, and you can book at any time</p>
        </div>
        <div className={css(styles.featureContainer)}>
          <TrustedIcon className={css(styles.icon)}/>
          <h2 className={css(styles.h2)}>Removal experts</h2>
          <p className={css(styles.p)}>We have local, experienced movers all over London and the UK on our platform</p>
        </div>
      </div>
    </div>
  </div>
);

export default FeatureList;

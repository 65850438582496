import { StyleSheet } from 'aphrodite';
import QUERIES from '../../../styles/queries';
import COLORS from '../../../styles/colors';

const styles = StyleSheet.create({
  root: {
    background: 'white',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto',
    padding: 32,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    [QUERIES.desktop]: {
      paddingBottom: 124
    }
  },
  descContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  contentCard: {},
  h1: {
    margin: 0,
    color: COLORS.jjdGreyish,
    fontWeight: 600,
    fontSize: 48,
    [QUERIES.mobile]: {
      textAlign: 'center'
    }
  },
  p: {
    maxWidth: 480,
    fontSize: 20,
    color: COLORS.jjdGreyish,
    fontWeight : 'normal',
    [QUERIES.mobile]: {
      textAlign: 'center'
    }
  },
  ul: {
    paddingLeft: 16
  },
  li: {
    height: 48,
    display: 'flex'
  },
  imgContainer: {
    textAlign: 'right',
    flex: '0 0 1',
    paddingTop: 32,
    [QUERIES.desktop]: {
      paddingTop: 0,
      flex: '0 0 50%',
      margin: '0 0 auto'
    }
  },
  img: {
    maxWidth: '100%'
  },
  getStartedButton: {
    marginTop: 50,
    background: COLORS.jjdRed,
    color: COLORS.white,
    textTransform: 'capitalize',
    borderRadius: '48px',
    borderColor: 'transparent',
    fontWeight: 'normal',
    fontSize: 20,
    textAlign: 'center',
    padding: '0 60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer'
    },
    [QUERIES.mobile]: {
      margin: '0 auto',
    }
  },
});

export default styles;

import React from "react";
import styles from "./HelpLifting.styles";
import Button from "@material-ui/core/Button/Button";
import { navigate } from "gatsby";
import { css } from "aphrodite";
import STYLES from "../../../styles/shared";
import COLORS from "../../../styles/colors";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const HelpLifting = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>
      <div className={css(styles.descContainer)}>
        <div>
          <h1 className={css(styles.h1)}>Need help lifting?</h1>
          <p className={css(styles.p)}>
            Get up to 3 people to help onload and offload your stuff. You can
            also choose to ride along in the van
          </p>
          <Button
            style={{ height: 60 }}
            disabled={props.loading}
            variant="contained"
            className={css(styles.getStartedButton)}
            onClick={() => {
              navigate("/book");
            }}
          >
            {props.loading && <CircularProgress color={"inherit"} />}
            {!props.loading && "Get Started"}
          </Button>
        </div>
      </div>
      <div className={css(styles.imgContainer)}>
        <img
          alt={"Lifting boxes shutterstock"}
          className={css(styles.img, STYLES.noSelect)}
          draggable={"false"}
          src={require("../../../assets/splash/lifting.png")}
        />
      </div>
    </div>
  </div>
);

export default HelpLifting;
